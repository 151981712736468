/* eslint-disable */

import React, { useState, useEffect } from "react";
import style from "./Dienstleistungen.module.css";
import arrow from "../../assets/orangeArrow.svg";
import kundenservice from "../../assets/kundenservice.svg";
import tick from "../../assets/tick.svg";
import {
  getInboundData,
  getKundenserviceData,
  getServicesForCards,
  getOutboundData,
} from "../../services/getServicesForCards.js";

const Dienstleistungen = () => {
  const [serviceCards, setServicesCards] = useState([]);
  const [kundenserviceData, setKundenserviceData] = useState([]);
  const [inboundData, setInboundData] = useState([]);
  const [outboundData, setOutboundData] = useState([]);

  const fetchServicesForCards = async () => {
    try {
      const response = await getServicesForCards();

      if (response) {
        setServicesCards(response);
      }
    } catch (error) {
      console.error("Failed to fetch about us info:", error);
    }
  };
  const fetchOutboundData = async () => {
    try {
      const response = await getOutboundData();

      if (response) {
        setOutboundData(response);
      }
    } catch (error) {
      console.error("Failed to fetch kundenservice info:", error);
    }
  };
  const fetchInboundData = async () => {
    try {
      const response = await getInboundData();

      if (response) {
        setInboundData(response);
      }
    } catch (error) {
      console.error("Failed to fetch kundenservice info:", error);
    }
  };

  const fetchKundenserviceData = async () => {
    try {
      const response = await getKundenserviceData();

      if (response) {
        setKundenserviceData(response);
      }
    } catch (error) {
      console.error("Failed to fetch kundenservice info:", error);
    }
  };

  useEffect(() => {
    fetchServicesForCards();
    fetchKundenserviceData();
    fetchInboundData();
    fetchOutboundData();
  }, []);

  return (
    <div>
      <div className={style.servicesSection}>
        <div className={style.services}>
          <h3>{serviceCards?.title}</h3>
          <p style={{ textAlign: "center", fontFamily: "Lato" }}>
            <strong>{serviceCards?.sub_title}</strong>
            <br />
            {serviceCards?.description}
          </p>
        </div>

        {serviceCards?.cards
          ?.reduce((rows, item, key) => {
            if (key % 3 === 0) {
              rows.push([]);
            }
            rows[rows.length - 1].push(item);
            return rows;
          }, [])
          .map((row, rowIndex) => (
            <div
              key={rowIndex}
              className={style.servicesSectionContent}
              style={{
                marginTop: rowIndex > 0 ? "2rem" : "0",
              }}
            >
              {row.map((item, key) => (
                <div className={style.parent} key={key}>
                  <div className={style.card}>
                    <div className={style.logo}>
                      <span
                        className={`${style.circle} ${style.circle1}`}
                      ></span>
                      <span
                        className={`${style.circle} ${style.circle2}`}
                      ></span>
                      <span
                        className={`${style.circle} ${style.circle3}`}
                      ></span>
                      <span
                        className={`${style.circle} ${style.circle4}`}
                      ></span>
                      <span className={`${style.circle} ${style.circle5}`}>
                        <svg
                          height="30"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          className={style.svg}
                        >
                          <text x="5" y="15" fill="white">
                            WKS
                          </text>
                        </svg>
                      </span>
                    </div>
                    <div className={style.glass}></div>
                    <div className={style.contentCardddd}>
                      <span className={style.title}>{item.title}</span>
                      <span className={style.text}>{item.description}</span>
                    </div>
                    <div className={style.bottom}>
                      <div className={style.socialButtonsContainer}>
                        <button
                          className={`${style.socialButton} ${style.socialButton1}`}
                        >
                          W
                        </button>
                        <button
                          className={`${style.socialButton} ${style.socialButton2}`}
                        >
                          K
                        </button>
                        <button
                          className={`${style.socialButton} ${style.socialButton3}`}
                        >
                          S
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className={style.secondSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.createSurveySection}>
            <h3>{kundenserviceData?.title}</h3>
            <div className={style.surveyContent}>
              {kundenserviceData?.kundenservice?.map((item, index) => (
                <div className={style.innerContent}>
                  <img src={arrow} alt="arrow" />
                  <p>{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={style.servicesDiv}>
            <img
              src={`https://prodapi.wkscompany.ch/${kundenserviceData?.img_url}`}
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
        </div>
      </div>
      <div className={style.firstSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.servicesDiv}>
            <img
              src={`https://prodapi.wkscompany.ch/${inboundData?.img_url}`}
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
          <div className={style.createSurveySection}>
            <h3 className={style.inboundH3}>{inboundData?.title}</h3>
            <div className={style.surveyContent}>
              {inboundData?.descriptions?.map((item, index) => (
                <div className={style.innerContent}>
                  <img src={tick} alt="arrow" />
                  <p className={style.inboundP}>{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={style.secondSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.createSurveySection}>
            <h3>{outboundData?.title}</h3>
            <div className={style.surveyContent}>
              {outboundData?.descriptions?.map((item, index) => (
                <>
                  <div className={style.innerContent}>
                    <img src={arrow} alt="arrow" />
                    <p>{item?.description}</p>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className={style.servicesDiv}>
            <img
              src={`https://prodapi.wkscompany.ch/${outboundData?.img_url}`}
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dienstleistungen;
