/* eslint-disable */
import React, { useState, useEffect } from "react";
import style from "../../styles/Footer.module.css";
import wksLogo from "../../assets/wkslogo.png";
import email from "../../assets/mail.svg";
import phone from "../../assets/phone.svg";
import { fetchFooter } from "../services/footerService";
import { Link } from "react-router-dom";

const Footer = () => {
  // Function to handle WhatsApp redirect
  const redirectToWhatsApp = (phoneNumber) => {
    // Removing any special characters from phone number
    const cleanNumber = phoneNumber.replace(/[^\d]/g, "");
    return `https://wa.me/${cleanNumber}`;
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [formData, setFormData] = useState({
    id: null,
    mainDescription: "",
    SecondDescription: "",
    phoneNumber: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchFooter();
        if (data.footer) {
          setFormData({
            id: data.footer.id,
            mainDescription: data.footer.main_description,
            SecondDescription: data.footer.second_description,
            phoneNumber: data.footer.phone_number,
            email: data.footer.email,
            address: data.footer.address,
          });
        }
      } catch (error) {
        console.error("Error fetching footer section:", error);
      }
    };

    getData();
  }, []);

  return (
    <div className={style.footerSection}>
      <div className="flex flex-col gap-10 w-full">
        <div className="flex flex-col 2xl:flex-row justify-between items-center gap-10">
          <div className={style.firstRow}>
            <div>
              <img
                src="/assets/finalLogo.png"
                alt="WKS logo"
                className={style.wksLogo}
              />
              <p className={style.p}>
                <strong>
                  {formData.mainDescription} <br />
                  {formData.SecondDescription}
                </strong>
              </p>
            </div>
          </div>
          <div className={style.secondPart}>
            <div className={style.contactSuportBrandsSection}>
              <h5 className={style.h5}>Links</h5>
              <div className={style.supportBrandContent}>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/uber-uns"
                    onClick={scrollToTop}
                  >
                    Über Uns
                  </Link>
                </h5>

                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/dienstleistungen"
                    onClick={scrollToTop}
                  >
                    Dienstleistungen
                  </Link>
                </h5>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/karriere"
                    onClick={scrollToTop}
                  >
                    Karriere
                  </Link>
                </h5>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/kontakt"
                    onClick={scrollToTop}
                  >
                    Kontakt
                  </Link>
                </h5>
              </div>
            </div>
            <div className={style.contactSuportBrandsSection}>
              <h5 className={style.h5}>Dienstleistungen</h5>
              <div className={style.supportBrandContent}>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/dienstleistungen"
                    onClick={scrollToTop}
                  >
                    Kundenservice
                  </Link>
                </h5>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/dienstleistungen"
                    onClick={scrollToTop}
                  >
                    Inbound
                  </Link>
                </h5>
                <h5 className={style.h7}>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="/dienstleistungen"
                    onClick={scrollToTop}
                  >
                    Outbound
                  </Link>
                </h5>
              </div>
            </div>
            <div className={style.contactSuportBrandsSection}>
              <h5 className={style.h5}>Kontakt</h5>
              <div className={style.contactContent}>
                <div className={style.contentt}>
                  <h5 className={style.h7Contact}>
                    {" "}
                    Vertretung <br />
                    (deutschsprachiger Raum) <br />
                    CH / A / D
                  </h5>
                </div>
                <div className={style.contentt}>
                  <img src={phone} alt="phone" />
                  <Link
                    className={style.h7}
                    to={redirectToWhatsApp(formData.phoneNumber)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {formData.phoneNumber}
                  </Link>
                </div>
                <div className={style.contentt}>
                  <img src={email} alt="mail" />
                  <Link
                    className={style.h7}
                    style={{ textDecoration: "none" }}
                    to={"mailto: info@wksteam.cc"}
                  >
                    {formData.email}
                  </Link>
                </div>
                <div className={style.contentt}>
                  {/* <h5 className={style.h7Contact}>{formData.address}</h5> */}
                  <h5 className={style.h7Contact}>
                    Goldbrunnenstrasse 42
                    <br />
                    CH - 9000 St. Gallen
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t border-white/20 mt-10 px-4">
          <div
            className={`${style.legalLinks} flex-wrap 2xl:justify-start justify-center`}
          >
            <Link
              to="/impressum"
              className={style.legalLink}
              onClick={scrollToTop}
            >
              Impressum
            </Link>
            <Link
              to="/datenschutz"
              className={style.legalLink}
              onClick={scrollToTop}
            >
              Datenschutz
            </Link>
            <Link to="/agb" className={style.legalLink} onClick={scrollToTop}>
              AGB
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
