import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./ApplicationForm.module.css";
import { useParams } from "react-router-dom";
import { getJobsByID } from "../../../services/jobService";
import { toast, ToastContainer } from "react-toastify";

function ApplicationForm() {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    description: "",
    cv: null,
    video: null,
    cvName: "", // Added to hold the name of the CV file
    videoName: "", // Added to hold the name of the video file
  });

  const initalData = {
    id: null,
    title: "",
    summary: "",
    description: "",
    location: "",
    employment_type: "",
    open_positions: null,
    is_active: null,
    created_at: "",
    updated_at: "",
  };

  const [jobDetails, setJobDetails] = useState(initalData);

  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const { jobId } = useParams();

  const fetchJobDataById = async (JobID) => {
    try {
      const response = await getJobsByID(JobID);
      if (response && response.job) {
        setJobDetails(response.job);
      }
      console.log("Job data fetched:", response);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  useEffect(() => {
    if (jobId) {
      fetchJobDataById(jobId);
    }
  }, [jobId]);

  // const handleChange = (e) => {
  //   const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: value,
  //   });
  // };
  const handleChange = (e) => {
    const { type, name, files, value } = e.target;
    const file = type === "file" ? files[0] : null;
    setFormData({
      ...formData,
      [name]: type === "file" ? file : value,
      ...(file && { [`${name}Name`]: file.name }), // Set the file name if type is file
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const data = new FormData();
  //   data.append("fullname", formData.fullname);
  //   data.append("email", formData.email);
  //   data.append("description", formData.description);
  //   data.append("cv", formData.cv);
  //   data.append("video", formData.video);

  //   for (let [key, value] of data.entries()) {
  //     console.log(`${key}: ${value}`);
  //   }
  //   console.log("Form Values:", {
  //     fullname: formData.fullname,
  //     email: formData.email,
  //     description: formData.description,
  //     cvName: formData.cvName,
  //     videoName: formData.videoName,
  //   });

  //   // try {
  //   //   // Replace with your actual endpoint
  //   //   const response = await axios.post("/api/applications", data, {
  //   //     headers: {
  //   //       "Content-Type": "multipart/form-data",
  //   //     },
  //   //   });
  //   //   // Handle the response here, e.g., show a success message
  //   //   console.log(response.data);
  //   // } catch (error) {
  //   //   // Handle the error here, e.g., show an error message
  //   //   console.error(error.response ? error.response.data : error.message);
  //   // }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("job_id", jobId); // Assuming jobId is from useParams() or a similar source
    data.append("full_name", formData.fullname);
    data.append("email", formData.email);
    data.append("description", formData.description);
    if (formData.cv) {
      data.append("cv", formData.cv, formData.cvName);
    }
    if (formData.video) {
      data.append("video", formData.video, formData.videoName);
    }
    data.append("education_level", formData.education_level || ""); // Assuming there's a field for this in your state

    try {
      const response = await axios.post(
        "https://prodapi.wkscompany.ch/api/application",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-TOKEN": "",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data.message);
        setFormData({
          fullname: "",
          email: "",
          description: "",
          cv: null,
          video: null,
          cvName: "",
          videoName: "",
        });
      }
    } catch (error) {
      console.error(
        "Error submitting application:",
        error.response ? error.response.data : error.message
      );
      // Handle the error here, e.g., showing an error message
    }
  };

  const handleNavigation = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className={style.applicationForm}>
      <div className={style.jobDetailsStyle}>
        <h1>{jobDetails.title}</h1>
        <h3 className="mt-3">{jobDetails?.summary}</h3>
        <div className={style.styleLocation}>
          <span>{jobDetails?.employment_type}</span>
          <span>{jobDetails?.location}</span>
        </div>
        <div
          id="jobsId"
          dangerouslySetInnerHTML={{ __html: jobDetails?.description }}
        ></div>
      </div>
      {/* <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Your name
        </label>
        <input
          type="text"
          name="firstName"
          placeholder="Emri"
          value={formData.firstName}
          onChange={handleChange}
          required
          className="px-4 py-2 border rounded shadow-sm"
        />
        <label className="block text-sm font-medium text-gray-700">
          Your username
        </label>
        <input
          type="text"
          name="lastName"
          placeholder="Mbiemri"
          value={formData.lastName}
          onChange={handleChange}
          required
          className="px-4 py-2 border rounded shadow-sm"
        />
        <label
          htmlFor="text"
          className="block text-sm font-medium text-gray-700"
        >
          Your school
        </label>
        <input
          type="text"
          name="school"
          placeholder="Shkolla"
          value={formData.school}
          onChange={handleChange}
          required
          className="px-4 py-2 border rounded shadow-sm"
        />
        <label htmlFor="cv" className="block text-sm font-medium text-gray-700">
          Upload your CV (PDF, DOC, DOCX)
        </label>
        <input
          type="file"
          name="cv"
          onChange={handleChange}
          required
          accept=".pdf,.doc,.docx"
          className="px-4 py-2 border rounded shadow-sm file:cursor-pointer file:px-4 file:py-2 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
        />
        <label
          htmlFor="video"
          className="block text-sm font-medium text-gray-700"
        >
          Upload your short self description video (max 2 minutes)
        </label>{" "}
        <input
          type="file"
          name="video"
          onChange={handleChange}
          required
          accept="video/*"
          className="px-4 py-2 border rounded shadow-sm file:cursor-pointer file:px-4 file:py-2 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form> */}
      <div className={style.firstContent}>
        <h1
          style={{
            color: "rgb(100, 77, 217)",
            fontFamily: "Poppins",
            fontWeight: "700",
            fontSize: "38px",
            lineHeight: "70px",
          }}
        >
          {/* How it works */}
          Wie funktionieren wir?
        </h1>
        <div className={style.firstContent} id="step1">
          <span className={style.spanStyle}>Schritt eins</span>
          <br />
          <span className={style.span2Style}>Online-Bewerbung</span>
          <br />
          <p>
            {/* Ju mbushni formularin me të dhënat tuaja për të na lejuar të mësojmë për
        ju. Dhe për të qenë të qartë, mund të ndodhë që për momentin të mos kemi
        një pozicion të hapur që i përshtatet plotësisht profilin tuaj.
        Megjithatë, nëse jeni të jashtëzakonshëm, nuk do të donim të humbnim
        mundësinë për t'ju njohur dhe do të mund të gjenim një mënyrë për t'ju
        integruar në ekipin tonë. */}
            Sie füllen das Formular mit Ihren Daten aus, damit wir mehr über Sie
            erfahren können. Und nur zur Klarstellung, es kann sein, dass wir
            momentan keine offene Stelle haben, die genau zu Ihrem Profil passt.
            Wenn Sie jedoch außergewöhnlich sind, möchten wir nicht die Chance
            verpassen, Sie kennenzulernen, und wir könnten einen Weg finden, Sie
            in unser Team zu integrieren.
          </p>{" "}
        </div>
        {/* <a
          href="#step2"
          className="chevron-down"
          style={{ marginBottom: "6rem" }}
        > */}
        <button
          onClick={() => handleNavigation("step2")}
          className="chevron-down"
          style={{ marginBottom: "12rem" }}
        >
          <svg
            id="ChevronDown_Icon_48"
            data-name="ChevronDown Icon 48"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <g id="chevrons-down" transform="translate(7 6)">
              <path
                id="Контур_640"
                data-name="Контур 640"
                d="M7,13,17,23,27,13"
                transform="translate(0 7)"
                fill="none"
                stroke="#a654ab"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
              <path
                id="Контур_641"
                data-name="Контур 641"
                d="M7,6,17,16,27,6"
                fill="none"
                stroke="#a654ab"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
            </g>
            <rect
              id="Прямоугольник_598"
              data-name="Прямоугольник 598"
              width="48"
              height="48"
              fill="none"
            ></rect>
          </svg>
        </button>
        {/* <a
          href="#step1"
          className="chevron-top"
          style={{ transform: "rotate(180deg)" }}
        > */}
        <button
          onClick={() => handleNavigation("step1")}
          className="chevron-top"
          style={{ transform: "rotate(180deg)" }}
        >
          <svg
            id="ChevronDown_Icon_48"
            data-name="ChevronDown Icon 48"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <g id="chevrons-down" transform="translate(7 6)">
              <path
                id="Контур_640"
                data-name="Контур 640"
                d="M7,13,17,23,27,13"
                transform="translate(0 7)"
                fill="none"
                stroke="#a654ab"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
              <path
                id="Контур_641"
                data-name="Контур 641"
                d="M7,6,17,16,27,6"
                fill="none"
                stroke="#a654ab"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
            </g>
            <rect
              id="Прямоугольник_598"
              data-name="Прямоугольник 598"
              width="48"
              height="48"
              fill="none"
            ></rect>
          </svg>
        </button>

        <div className={style.firstContent} id="step2">
          <span className={style.spanStyle}>Schritt zwei</span>
          <br />
          <span className={style.span2Style}>Chemie-Treffen</span>
          <br />
          <p>
            {/* Konsiderojeni këtë si një takim parësor profesional. Ky është momenti ku ne do të përpiqemi të njihemi më mirë me ju, dhe ju jeni të lirë të na bëni çdo pyetje që mund të keni. Nëse përshtypjet janë pozitive dhe mjedisi është i përshtatshëm, është e mundur që të zgjerohet bashkëpunimi ynë në të ardhmen */}
            Betrachten Sie dies als ein vorbereitendes professionelles Treffen.
            Dies ist der Moment, in dem wir versuchen werden, Sie besser
            kennenzulernen, und Sie sind frei, uns jegliche Fragen zu stellen,
            die Sie haben mögen. Wenn die Eindrücke positiv sind und die
            Umgebung geeignet ist, ist es möglich, dass unsere Zusammenarbeit in
            Zukunft erweitert wird.
          </p>{" "}
        </div>
        <button
          className={style.startApplicationButton}
          onClick={() => setShowApplicationForm(true)}
        >
          Start your application
        </button>
      </div>
      {showApplicationForm && (
        <>
          <ToastContainer position="top-right" />

          <div
            className={`${style.carddd} "d-flex align-items-center card p-5"`}
            style={{
              background: "white",
              width: "100%",
              border: "2px solid #feebff",
            }}
          >
            <div className={style.contactForm}>
              <form id="contact-form" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contactForm" />
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {/* <input
                      type="text"
                      name="fullname"
                      className="form-control"
                      id="fullname"
                      placeholder="Bitte geben Sie Ihren Namen ein *"
                      required="required"
                    /> */}
                      <input
                        type="text"
                        name="fullname"
                        className="form-control"
                        id="fullname"
                        placeholder="Bitte geben Sie Ihren Namen ein *"
                        required="required"
                        value={formData.fullname}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      {/* <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein *"
                      required="required"
                    /> */}
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein *"
                        required="required"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <label className="mb-3 mt-5">
                    Lade deinen Lebenslauf hoch
                  </label> */}
                    <div className="form-group">
                      <textarea
                        rows="1"
                        name="description"
                        className="form-control"
                        id="description"
                        placeholder="Bitte beschreiben Sie sich selbst mit ein paar Worten *"
                        required="required"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "1rem" }}>
                    <label className="mb-5 mt-5">
                      Lade deinen Lebenslauf hoch
                    </label>
                    <div className="form-group">
                      <form className={style.fileUploadForm}>
                        <label for="cv" className={style.fileUploadLabel}>
                          <div className={style.fileUploadDesign}>
                            <svg viewBox="0 0 640 512" height="1em">
                              <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                            </svg>
                            {!formData.cvName && (
                              <>
                                <p>Drag and Drop</p>
                                <p>or</p>
                              </>
                            )}
                            <span className="browse-button">
                              {formData.cvName || "Browse file"}{" "}
                            </span>
                          </div>
                          <input
                            id="cv"
                            type="file"
                            name="cv"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </label>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "1rem" }}>
                    <label className="mb-5 mt-5">
                      Drehe ein kurzes Video, in dem du dich auf Deutsch
                      vorstellst.{"  "}
                    </label>
                    <a
                      style={{ color: "red" }}
                      href="https://www.veed.io/tools/video-compressor/mp4-compressor"
                      target="_blank"
                    >
                      {"  "} If your video contains more than 10MB please
                      downgrate here
                      {/* https://www.veed.io/tools/video-compressor/mp4-compressor */}
                    </a>
                    <div className="form-group file-uploading mt-10">
                      <form className={style.fileUploadForm}>
                        <label for="video" className={style.fileUploadLabel}>
                          <div className={style.fileUploadDesign}>
                            <svg viewBox="0 0 640 512" height="1em">
                              <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                            </svg>
                            {!formData.videoName && (
                              <>
                                <p>Drag and Drop</p>
                                <p>or</p>
                              </>
                            )}
                            <span className="browse-button">
                              {formData.videoName || "Browse your video"}{" "}
                            </span>
                          </div>
                          <input
                            id="video"
                            type="file"
                            name="video"
                            accept="video/*"
                            required
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </label>
                      </form>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="file"
                      name="cv"
                      onChange={handleChange}
                      required
                      accept=".pdf,.doc,.docx"
                      className={`${style.fileAttach}
                      "px-4 py-2 border rounded shadow-sm file:cursor-pointer file:px-4 file:py-2 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"`}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <lable>
                    Drehe ein kurzes Video, in dem du dich auf Deutsch
                    vorstellst.
                  </lable>
                  <div className="form-group">
                    <input
                      type="file"
                      name="video"
                      onChange={handleChange}
                      required
                      accept="video/*"
                      className={`${style.fileAttach} "px-4 py-2 border rounded shadow-sm file:cursor-pointer file:px-4 file:py-2 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"`}
                    />
                  </div>
                </div> */}
                  <div className="col-md-12 mt-5">
                    <button
                      type="submit"
                      // onClick={handleSubmit}
                      style={{
                        background: "#a654ab",
                        color: "white",
                        fontWeight: "bold",
                        padding: "0.5rem 2rem",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      className={`${style.aplyButton} btn`}
                    >
                      Absenden <i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ApplicationForm;
