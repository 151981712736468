import Axios from "../utils/axios";

const getServices = async () => {
  try {
    const response = await Axios.get("/services");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
export { getServices };
