import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://prodapi.wkscompany.ch/api"
    : "https://prodapi.wkscompany.ch/api";

const Axios = axios.create({
  baseURL: baseURL,
});

export default Axios;
