import Axios from "../utils/axios";

const getJobs = async () => {
  try {
    const response = await Axios.get("/jobs/active");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

const getJobsByID = async (jobID) => {
  try {
    const response = await Axios.get(`/jobs/${jobID}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export { getJobs, getJobsByID };
