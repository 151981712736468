import React from "react";
import { Link } from "react-router-dom";
const PrivatcyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Datenschutzerklärung
        </h1>

        <div className="space-y-8">
          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <p className="text-gray-300">
              Zuletzt aktualisiert: 20. Januar 2025
            </p>
            <p className="text-gray-300">
              Diese Datenschutzerklärung beschreibt unsere Richtlinien und
              Verfahren zur Erhebung, Nutzung und Offenlegung Ihrer
              Informationen, wenn Sie den Dienst nutzen, und informiert Sie über
              Ihre Datenschutzrechte und darüber, wie das Gesetz Sie schützt.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Auslegung und Definitionen
            </h2>
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Auslegung
            </h3>
            <p className="text-gray-300">
              Die Wörter, deren Anfangsbuchstabe großgeschrieben ist, haben
              unter den folgenden Bedingungen definierte Bedeutungen. Die
              folgenden Definitionen haben dieselbe Bedeutung, unabhängig davon,
              ob sie im Singular oder im Plural erscheinen.
            </p>

            <h3 className="text-xl font-semibold mt-6 mb-3 text-purple-400">
              Definitionen
            </h3>
            <p className="text-gray-300">
              Für die Zwecke dieser Datenschutzerklärung:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                <p>
                  <strong>Konto</strong> bezeichnet ein einzigartiges Konto, das
                  für Sie erstellt wurde, um auf unseren Dienst oder Teile
                  unseres Dienstes zuzugreifen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Partner</strong> bezeichnet eine Einheit, die eine
                  Partei kontrolliert, von ihr kontrolliert wird oder unter
                  gemeinsamer Kontrolle mit einer Partei steht, wobei
                  "Kontrolle" den Besitz von 50% oder mehr der Aktien,
                  Eigenkapitalanteile oder anderen Wertpapiere bedeutet, die zur
                  Wahl von Direktoren oder anderen leitenden Autoritäten
                  berechtigt sind.
                </p>
              </li>
              <li>
                <p>
                  <strong>Unternehmen</strong> (in dieser Vereinbarung als "das
                  Unternehmen", "Wir", "Uns" oder "Unser" bezeichnet) bezieht
                  sich auf Wks Company, XK, Galip Badivuku, Vushtrri 42000.
                  Handelsregisternummer: KS811415638
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies</strong> sind kleine Dateien, die von einer
                  Website auf Ihrem Computer, mobilem Gerät oder einem anderen
                  Gerät platziert werden und die Details Ihrer Browsing-Historie
                  auf dieser Website sowie viele andere Verwendungszwecke
                  enthalten.
                </p>
              </li>
              <li>
                <p>
                  <strong>Land</strong> bezieht sich auf: Kosovo
                </p>
              </li>
              <li>
                <p>
                  <strong>Gerät</strong> bezeichnet jedes Gerät, das auf den
                  Dienst zugreifen kann, wie z.B. ein Computer, ein Mobiltelefon
                  oder ein digitales Tablet.
                </p>
              </li>
              <li>
                <p>
                  <strong>Personenbezogene Daten</strong> sind alle
                  Informationen, die sich auf eine identifizierte oder
                  identifizierbare Person beziehen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Dienst</strong> bezieht sich auf die Website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Dienstleister</strong> bezeichnet jede natürliche oder
                  juristische Person, die die Daten im Auftrag des Unternehmens
                  verarbeitet. Es bezieht sich auf Drittunternehmen oder
                  Einzelpersonen, die vom Unternehmen beschäftigt werden, um den
                  Dienst zu ermöglichen, den Dienst im Namen des Unternehmens
                  bereitzustellen, dienstbezogene Leistungen zu erbringen oder
                  dem Unternehmen bei der Analyse der Nutzung des Dienstes zu
                  helfen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Nutzungsdaten</strong> beziehen sich auf automatisch
                  erhobene Daten, die entweder durch die Nutzung des Dienstes
                  oder durch die Dienstinfrastruktur selbst generiert werden
                  (zum Beispiel die Dauer eines Seitenbesuchs).
                </p>
              </li>
              <li>
                <p>
                  <strong>Website</strong> bezieht sich auf Wks Company,
                  erreichbar unter{" "}
                  <a
                    href="https://wkscompany.ch/"
                    rel="external nofollow noopener"
                    target="_blank"
                    className="text-purple-400 hover:text-purple-300"
                  >
                    https://wkscompany.ch/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <strong>Sie</strong> bezeichnet die Person, die auf den Dienst
                  zugreift oder ihn nutzt, oder das Unternehmen oder eine andere
                  juristische Person, in deren Namen diese Person auf den Dienst
                  zugreift oder ihn nutzt.
                </p>
              </li>
            </ul>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Erhebung und Verwendung Ihrer personenbezogenen Daten
            </h2>
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Arten von erhobenen Daten
            </h3>
            <h4 className="text-lg font-semibold mb-2 text-purple-400">
              Personenbezogene Daten
            </h4>
            <p className="text-gray-300">
              Während der Nutzung unseres Dienstes können wir Sie bitten, uns
              bestimmte personenbezogene Informationen zur Verfügung zu stellen,
              die zur Kontaktaufnahme oder Identifizierung verwendet werden
              können. Personenbezogene Informationen können unter anderem
              Folgendes umfassen:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                <p>E-Mail-Adresse</p>
              </li>
              <li>
                <p>Vor- und Nachname</p>
              </li>
              <li>
                <p>Nutzungsdaten</p>
              </li>
            </ul>

            <h4 className="text-lg font-semibold mt-4 mb-2 text-purple-400">
              Nutzungsdaten
            </h4>
            <p className="text-gray-300">
              Nutzungsdaten werden bei der Nutzung des Dienstes automatisch
              erhoben.
            </p>
            <p className="text-gray-300">
              Nutzungsdaten können Informationen wie die
              Internet-Protokoll-Adresse Ihres Geräts (z.B. IP-Adresse),
              Browsertyp, Browserversion, die von Ihnen besuchten Seiten unseres
              Dienstes, Zeitpunkt und Datum Ihres Besuchs, die auf diesen Seiten
              verbrachte Zeit, eindeutige Gerätekennungen und andere
              Diagnosedaten umfassen.
            </p>
            <p className="text-gray-300">
              Wenn Sie auf den Dienst über ein mobiles Gerät zugreifen, können
              wir automatisch bestimmte Informationen erheben, einschließlich,
              aber nicht beschränkt auf den Typ des von Ihnen verwendeten
              mobilen Geräts, die eindeutige ID Ihres mobilen Geräts, die
              IP-Adresse Ihres mobilen Geräts, Ihr mobiles Betriebssystem, den
              Typ des von Ihnen verwendeten mobilen Internetbrowsers, eindeutige
              Gerätekennungen und andere Diagnosedaten.
            </p>
            <p className="text-gray-300">
              Wir können auch Informationen erheben, die Ihr Browser sendet,
              wenn Sie unseren Dienst besuchen oder wenn Sie auf den Dienst über
              ein mobiles Gerät zugreifen.
            </p>

            <h4 className="text-lg font-semibold mt-4 mb-2 text-purple-400">
              Tracking-Technologien und Cookies
            </h4>
            <p className="text-gray-300">
              Wir verwenden Cookies und ähnliche Tracking-Technologien, um die
              Aktivität auf unserem Dienst zu verfolgen und bestimmte
              Informationen zu speichern. Zu den verwendeten
              Tracking-Technologien gehören Beacons, Tags und Scripts zur
              Erhebung und Verfolgung von Informationen sowie zur Verbesserung
              und Analyse unseres Dienstes. Die von uns verwendeten Technologien
              können Folgendes umfassen:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                <strong>Cookies oder Browser-Cookies.</strong> Ein Cookie ist
                eine kleine Datei, die auf Ihrem Gerät platziert wird. Sie
                können Ihren Browser anweisen, alle Cookies abzulehnen oder
                anzuzeigen, wenn ein Cookie gesendet wird. Wenn Sie jedoch keine
                Cookies akzeptieren, können Sie möglicherweise einige Teile
                unseres Dienstes nicht nutzen. Sofern Sie Ihre
                Browsereinstellung nicht so angepasst haben, dass sie Cookies
                ablehnt, kann unser Dienst Cookies verwenden.
              </li>
              <li>
                <strong>Web Beacons.</strong> Bestimmte Abschnitte unseres
                Dienstes und unserer E-Mails können kleine elektronische Dateien
                enthalten, die als Web Beacons (auch als Clear Gifs, Pixel-Tags
                und Single-Pixel-Gifs bezeichnet) bekannt sind, die es dem
                Unternehmen beispielsweise ermöglichen, Benutzer zu zählen, die
                diese Seiten besucht oder eine E-Mail geöffnet haben, und für
                andere verwandte Website-Statistiken (zum Beispiel die
                Aufzeichnung der Beliebtheit eines bestimmten Abschnitts und die
                Überprüfung der System- und Serverintegrität).
              </li>
            </ul>

            <p className="text-gray-300">
              Cookies können "Persistent"- oder "Session"-Cookies sein.
              Persistent-Cookies verbleiben auf Ihrem persönlichen Computer oder
              mobilen Gerät, wenn Sie offline gehen, während Session-Cookies
              gelöscht werden, sobald Sie Ihren Webbrowser schließen.
            </p>

            <p className="text-gray-300">
              Wir verwenden sowohl Session- als auch Persistent-Cookies für die
              unten aufgeführten Zwecke:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                <p>
                  <strong>Notwendige / Essentielle Cookies</strong>
                </p>
                <p>Typ: Session-Cookies</p>
                <p>Verwaltet von: Uns</p>
                <p>
                  Zweck: Diese Cookies sind essentiell, um Ihnen die über die
                  Website verfügbaren Dienste bereitzustellen und Ihnen die
                  Nutzung einiger ihrer Funktionen zu ermöglichen. Sie helfen
                  bei der Authentifizierung von Benutzern und verhindern den
                  betrügerischen Gebrauch von Benutzerkonten. Ohne diese Cookies
                  können die von Ihnen angeforderten Dienste nicht
                  bereitgestellt werden, und wir verwenden diese Cookies nur, um
                  Ihnen diese Dienste bereitzustellen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookie-Richtlinie / Hinweisannahme-Cookies</strong>
                </p>
                <p>Typ: Persistent-Cookies</p>
                <p>Verwaltet von: Uns</p>
                <p>
                  Zweck: Diese Cookies identifizieren, ob Benutzer die
                  Verwendung von Cookies auf der Website akzeptiert haben.
                </p>
              </li>
              <li>
                <p>
                  <strong>Funktionalitäts-Cookies</strong>
                </p>
                <p>Typ: Persistent-Cookies</p>
                <p>Verwaltet von: Uns</p>
                <p>
                  Zweck: Diese Cookies ermöglichen es uns, Ihre Entscheidungen
                  bei der Nutzung der Website zu speichern, wie zum Beispiel das
                  Speichern Ihrer Anmeldedaten oder Spracheinstellungen. Der
                  Zweck dieser Cookies ist es, Ihnen ein persönlicheres Erlebnis
                  zu bieten und zu vermeiden, dass Sie Ihre Präferenzen bei
                  jeder Nutzung der Website erneut eingeben müssen.
                </p>
              </li>
            </ul>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Verwendung Ihrer personenbezogenen Daten
            </h3>
            <p className="text-gray-300">
              Das Unternehmen kann personenbezogene Daten für folgende Zwecke
              verwenden:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                <p>
                  <strong>
                    Zur Bereitstellung und Wartung unseres Dienstes
                  </strong>
                  , einschließlich der Überwachung der Nutzung unseres Dienstes.
                </p>
              </li>
              <li>
                <p>
                  <strong>Zur Verwaltung Ihres Kontos:</strong> zur Verwaltung
                  Ihrer Registrierung als Benutzer des Dienstes. Die von Ihnen
                  bereitgestellten personenbezogenen Daten können Ihnen Zugang
                  zu verschiedenen Funktionalitäten des Dienstes gewähren, die
                  Ihnen als registriertem Benutzer zur Verfügung stehen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Zur Vertragserfüllung:</strong> die Entwicklung,
                  Einhaltung und Durchführung des Kaufvertrags für die Produkte,
                  Artikel oder Dienstleistungen, die Sie erworben haben, oder
                  eines anderen Vertrags mit uns über den Dienst.
                </p>
              </li>
              <li>
                <p>
                  <strong>Um Sie zu kontaktieren:</strong> Um Sie per E-Mail,
                  Telefonanrufe, SMS oder andere gleichwertige Formen der
                  elektronischen Kommunikation, wie Push-Benachrichtigungen
                  einer mobilen Anwendung, bezüglich Aktualisierungen oder
                  informativer Mitteilungen im Zusammenhang mit den
                  Funktionalitäten, Produkten oder vertraglich vereinbarten
                  Dienstleistungen zu kontaktieren, einschließlich der
                  Sicherheitsupdates, wenn dies für deren Implementierung
                  erforderlich oder angemessen ist.
                </p>
              </li>
              <li>
                <p>
                  <strong>Um Ihnen</strong> Neuigkeiten, Sonderangebote und
                  allgemeine Informationen über andere Waren, Dienstleistungen
                  und Veranstaltungen zu bieten, die wir anbieten und die denen
                  ähnlich sind, die Sie bereits gekauft oder nach denen Sie sich
                  erkundigt haben, es sei denn, Sie haben sich dagegen
                  entschieden, solche Informationen zu erhalten.
                </p>
              </li>
              <li>
                <p>
                  <strong>Zur Verwaltung Ihrer Anfragen:</strong> Um Ihre
                  Anfragen an uns zu bearbeiten und zu verwalten.
                </p>
              </li>
              <li>
                <p>
                  <strong>Für Geschäftsübertragungen:</strong> Wir können Ihre
                  Informationen verwenden, um eine Fusion, Veräußerung,
                  Umstrukturierung, Reorganisation, Auflösung oder einen anderen
                  Verkauf oder eine Übertragung einiger oder aller unserer
                  Vermögenswerte zu bewerten oder durchzuführen, sei es als
                  laufendes Geschäft oder als Teil eines Konkurs-, Liquidations-
                  oder ähnlichen Verfahrens, bei dem von uns gespeicherte
                  personenbezogene Daten über unsere Dienstnutzer zu den
                  übertragenen Vermögenswerten gehören.
                </p>
              </li>
              <li>
                <p>
                  <strong>Für andere Zwecke</strong>: Wir können Ihre
                  Informationen für andere Zwecke verwenden, wie z.B.
                  Datenanalyse, Identifizierung von Nutzungstrends, Bestimmung
                  der Wirksamkeit unserer Werbekampagnen sowie zur Bewertung und
                  Verbesserung unseres Dienstes, unserer Produkte,
                  Dienstleistungen, unseres Marketings und Ihrer Erfahrung.
                </p>
              </li>
            </ul>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Aufbewahrung Ihrer personenbezogenen Daten
            </h3>
            <p className="text-gray-300">
              Das Unternehmen wird Ihre personenbezogenen Daten nur so lange
              aufbewahren, wie es für die in dieser Datenschutzerklärung
              dargelegten Zwecke erforderlich ist. Wir werden Ihre
              personenbezogenen Daten in dem Umfang aufbewahren und verwenden,
              der erforderlich ist, um unseren rechtlichen Verpflichtungen
              nachzukommen (zum Beispiel, wenn wir Ihre Daten aufbewahren
              müssen, um geltende Gesetze einzuhalten), Streitigkeiten
              beizulegen und unsere rechtlichen Vereinbarungen und Richtlinien
              durchzusetzen.
            </p>
            <p className="text-gray-300">
              Das Unternehmen wird auch Nutzungsdaten für interne Analysezwecke
              aufbewahren. Nutzungsdaten werden in der Regel für einen kürzeren
              Zeitraum aufbewahrt, es sei denn, diese Daten werden zur Stärkung
              der Sicherheit oder zur Verbesserung der Funktionalität unseres
              Dienstes verwendet, oder wir sind gesetzlich verpflichtet, diese
              Daten für längere Zeiträume aufzubewahren.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Übermittlung Ihrer personenbezogenen Daten
            </h3>
            <p className="text-gray-300">
              Ihre Informationen, einschließlich personenbezogener Daten, werden
              in den Betriebsbüros des Unternehmens und an allen anderen Orten
              verarbeitet, an denen sich die an der Verarbeitung beteiligten
              Parteien befinden. Dies bedeutet, dass diese Informationen an
              Computer außerhalb Ihres Bundeslandes, Ihrer Provinz, Ihres Landes
              oder einer anderen staatlichen Jurisdiktion übertragen und dort
              gespeichert werden können, wo die Datenschutzgesetze sich von
              denen Ihrer Jurisdiktion unterscheiden können.
            </p>
            <p className="text-gray-300">
              Ihre Zustimmung zu dieser Datenschutzerklärung, gefolgt von Ihrer
              Übermittlung solcher Informationen, stellt Ihre Einwilligung in
              diese Übertragung dar.
            </p>
            <p className="text-gray-300">
              Das Unternehmen wird alle angemessenen Schritte unternehmen, um
              sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit
              dieser Datenschutzerklärung behandelt werden, und keine
              Übertragung Ihrer personenbezogenen Daten wird an eine
              Organisation oder ein Land erfolgen, es sei denn, es bestehen
              angemessene Kontrollen einschließlich der Sicherheit Ihrer Daten
              und anderer personenbezogener Informationen.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Löschung Ihrer personenbezogenen Daten
            </h3>
            <p className="text-gray-300">
              Sie haben das Recht, die Löschung oder die Unterstützung bei der
              Löschung der personenbezogenen Daten zu verlangen, die wir über
              Sie erhoben haben.
            </p>
            <p className="text-gray-300">
              Unser Dienst kann Ihnen die Möglichkeit geben, bestimmte
              Informationen über Sie innerhalb des Dienstes zu löschen.
            </p>
            <p className="text-gray-300">
              Sie können Ihre Informationen jederzeit aktualisieren, ändern oder
              löschen, indem Sie sich in Ihr Konto einloggen, falls Sie eines
              haben, und den Kontoeinstellungsbereich besuchen, der es Ihnen
              ermöglicht, Ihre persönlichen Informationen zu verwalten. Sie
              können uns auch kontaktieren, um Zugang zu den personenbezogenen
              Informationen zu erhalten, sie zu korrigieren oder zu löschen, die
              Sie uns zur Verfügung gestellt haben.
            </p>
            <p className="text-gray-300">
              Bitte beachten Sie jedoch, dass wir bestimmte Informationen
              aufbewahren müssen, wenn wir dazu gesetzlich verpflichtet sind
              oder eine rechtmäßige Grundlage dafür haben.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Offenlegung Ihrer personenbezogenen Daten
            </h3>
            <h4 className="text-lg font-semibold mb-2 text-purple-400">
              Geschäftstransaktionen
            </h4>
            <p className="text-gray-300">
              Wenn das Unternehmen an einer Fusion, Übernahme oder einem
              Vermögensverkauf beteiligt ist, können Ihre personenbezogenen
              Daten übertragen werden. Wir werden Sie benachrichtigen, bevor
              Ihre personenbezogenen Daten übertragen werden und einer anderen
              Datenschutzerklärung unterliegen.
            </p>

            <h4 className="text-lg font-semibold mt-4 mb-2 text-purple-400">
              Strafverfolgung
            </h4>
            <p className="text-gray-300">
              Unter bestimmten Umständen kann das Unternehmen verpflichtet sein,
              Ihre personenbezogenen Daten offenzulegen, wenn dies gesetzlich
              vorgeschrieben ist oder auf gültige Anfragen öffentlicher Behörden
              (z.B. eines Gerichts oder einer Regierungsbehörde) erfolgt.
            </p>

            <h4 className="text-lg font-semibold mt-4 mb-2 text-purple-400">
              Andere rechtliche Anforderungen
            </h4>
            <p className="text-gray-300">
              Das Unternehmen kann Ihre personenbezogenen Daten in gutem Glauben
              offenlegen, dass eine solche Maßnahme erforderlich ist, um:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>Einer rechtlichen Verpflichtung nachzukommen</li>
              <li>
                Die Rechte oder das Eigentum des Unternehmens zu schützen und zu
                verteidigen
              </li>
              <li>
                Mögliches Fehlverhalten im Zusammenhang mit dem Dienst zu
                verhindern oder zu untersuchen
              </li>
              <li>
                Die persönliche Sicherheit der Nutzer des Dienstes oder der
                Öffentlichkeit zu schützen
              </li>
              <li>Sich gegen rechtliche Haftung zu schützen</li>
            </ul>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-purple-400">
              Sicherheit Ihrer personenbezogenen Daten
            </h3>
            <p className="text-gray-300">
              Die Sicherheit Ihrer personenbezogenen Daten ist uns wichtig, aber
              denken Sie daran, dass keine Methode der Übertragung über das
              Internet oder Methode der elektronischen Speicherung 100% sicher
              ist. Während wir uns bemühen, kommerziell akzeptable Mittel zum
              Schutz Ihrer personenbezogenen Daten zu verwenden, können wir
              deren absolute Sicherheit nicht garantieren.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Datenschutz von Kindern
            </h2>
            <p className="text-gray-300">
              Unser Dienst richtet sich nicht an Personen unter 13 Jahren. Wir
              erheben wissentlich keine personenbezogenen Informationen von
              Personen unter 13 Jahren. Wenn Sie ein Elternteil oder
              Erziehungsberechtigter sind und Sie wissen, dass Ihr Kind uns
              personenbezogene Daten zur Verfügung gestellt hat, kontaktieren
              Sie uns bitte. Wenn wir feststellen, dass wir personenbezogene
              Daten von Personen unter 13 Jahren ohne Überprüfung der
              elterlichen Zustimmung erhoben haben, ergreifen wir Maßnahmen, um
              diese Informationen von unseren Servern zu entfernen.
            </p>
            <p className="text-gray-300">
              Wenn wir uns auf die Einwilligung als Rechtsgrundlage für die
              Verarbeitung Ihrer Informationen stützen müssen und Ihr Land die
              Einwilligung eines Elternteils erfordert, können wir die
              Einwilligung Ihrer Eltern verlangen, bevor wir diese Informationen
              erheben und verwenden.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Kontaktieren Sie uns
            </h2>
            <p className="text-gray-300 mb-4">
              Sie können uns auf folgenden Wegen erreichen:
            </p>
            <ul className="list-disc pl-5 text-gray-300 space-y-3">
              <li>
                <p>
                  Per E-Mail:{" "}
                  <a
                    href="mailto:info@wkscompany.ch"
                    className="text-purple-400 hover:text-purple-300"
                  >
                    info@wkscompany.ch
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Durch Besuch dieser Seite auf unserer Website:{" "}
                  <Link
                    to="/kontakt"
                    className="text-purple-400 hover:text-purple-300"
                  >
                    https://wkscompany.ch/kontakt
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  Per Telefon:{" "}
                  <a
                    href="tel:+38349244880"
                    className="text-purple-400 hover:text-purple-300"
                  >
                    +38349244880
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivatcyPolicy;
