/* eslint-disable */
import React, { useState, useEffect } from "react";
import style from "../../styles/AboutWKSSection.module.css";
import { getAboutUs } from "../../services/getAboutUsInfo";

const AboutWKSSection = () => {
  const [aboutUs, setAboutUs] = useState([]);

  useEffect(() => {
    const fetchAboutUsInfo = async () => {
      try {
        const response = await getAboutUs();
        console.log("response", response);

        if (response && response) {
          setAboutUs(response);
        }
      } catch (error) {
        console.error("Failed to fetch about us info:", error);
      }
    };

    fetchAboutUsInfo();
  }, []);

  return (
    <>
      <div className={style.aboutWKSSectionStyle}>
        <div className={style.content}>
          <h1 className={style.h1}>{aboutUs?.title}</h1>
          <p className={style.p}>{aboutUs?.description}</p>
        </div>
        <div className={style.specialCard}>
          {aboutUs?.sections?.map((section) => (
            <div key={section.id} className={style.serviceCardContainer}>
              <div className={style.serviceCard}>
                <div className={style.frontContent}>
                  <p>{section.title}</p>
                </div>
                <div className={style.serviceContent}>
                  <p className={style.serviceHeading}>{section.title}</p>
                  <p>{section.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={style.buttonsDiv}
          style={{ textAlign: "center", marginTop: "60px" }}
        >
          <a className={style.exploreAllbutton} href="/uber-uns">
            Erfahren Sie mehr über uns
          </a>
        </div>
      </div>
    </>
  );
};

export default AboutWKSSection;
