import React from "react";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Allgemeine Geschäftsbedingungen
        </h1>

        <div className="space-y-8">
          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <p className="text-gray-300">Willkommen bei Wks Company!</p>

            <p className="text-gray-300">
              Diese Allgemeinen Geschäftsbedingungen regeln die Nutzung der
              Website der Wks Company, die unter https://wkscompany.ch/ zu
              finden ist.
            </p>

            <p className="text-gray-300">
              Mit dem Zugriff auf diese Website akzeptieren Sie diese
              Geschäftsbedingungen. Bitte nutzen Sie Wks Company nicht weiter,
              wenn Sie nicht mit allen auf dieser Seite aufgeführten
              Geschäftsbedingungen einverstanden sind.
            </p>

            <p className="text-gray-300">
              Die folgende Terminologie gilt für diese Allgemeinen
              Geschäftsbedingungen, Datenschutzerklärung und Haftungsausschluss
              sowie alle Vereinbarungen: "Kunde", "Sie" und "Ihr" bezieht sich
              auf Sie, die Person, die sich auf dieser Website einloggt und den
              Geschäftsbedingungen des Unternehmens zustimmt. "Das Unternehmen",
              "Wir selbst", "Wir", "Unser" und "Uns" bezieht sich auf unser
              Unternehmen. "Partei", "Parteien" oder "Uns" bezieht sich sowohl
              auf den Kunden als auch auf uns selbst. Alle Begriffe beziehen
              sich auf das Angebot, die Annahme und die Berücksichtigung der
              Zahlung, die erforderlich ist, um den Prozess unserer
              Unterstützung für den Kunden in der am besten geeigneten Weise
              durchzuführen, und zwar ausdrücklich zum Zweck der Erfüllung der
              Bedürfnisse des Kunden in Bezug auf die Erbringung der angegebenen
              Dienstleistungen des Unternehmens, in Übereinstimmung mit und
              vorbehaltlich des geltenden niederländischen Rechts.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Cookies
            </h2>
            <p className="text-gray-300">
              Wir verwenden Cookies. Durch den Zugriff auf Wks Company stimmen
              Sie der Verwendung von Cookies gemäß der Datenschutzerklärung von
              Wks Company zu.
            </p>

            <p className="text-gray-300">
              Die meisten interaktiven Websites verwenden Cookies, damit wir die
              Benutzerdaten bei jedem Besuch abrufen können. Cookies werden von
              unserer Website verwendet, um die Funktionalität bestimmter
              Bereiche zu ermöglichen und den Besuch unserer Website zu
              erleichtern. Einige unserer Partner/Werbepartner verwenden
              möglicherweise ebenfalls Cookies.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Lizenz
            </h2>
            <p className="text-gray-300">
              Sofern nicht anders angegeben, besitzen Wks Company und/oder seine
              Lizenzgeber die geistigen Eigentumsrechte für alle Materialien auf
              Wks Company. Alle geistigen Eigentumsrechte sind vorbehalten. Sie
              können darauf von Wks Company für Ihren persönlichen Gebrauch
              zugreifen, vorbehaltlich der in diesen Geschäftsbedingungen
              festgelegten Einschränkungen.
            </p>

            <p className="text-gray-300">Sie dürfen nicht:</p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>Material von Wks Company neu veröffentlichen</li>
              <li>
                Material von Wks Company verkaufen, vermieten oder
                unterlizenzieren
              </li>
              <li>
                Material von Wks Company reproduzieren, vervielfältigen oder
                kopieren
              </li>
              <li>Inhalte von Wks Company weiterverbreiten</li>
            </ul>

            <p className="text-gray-300">
              Teile dieser Website bieten Benutzern die Möglichkeit, Meinungen
              und Informationen in bestimmten Bereichen der Website zu
              veröffentlichen und auszutauschen. Wks Company filtert,
              bearbeitet, veröffentlicht oder überprüft Kommentare nicht vor
              ihrer Präsenz auf der Website. Kommentare spiegeln nicht die
              Ansichten und Meinungen von Wks Company, seinen Vertretern
              und/oder verbundenen Unternehmen wider. Kommentare spiegeln die
              Ansichten und Meinungen der Person wider, die ihre Ansichten und
              Meinungen veröffentlicht.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Verlinken zu unseren Inhalten
            </h2>
            <p className="text-gray-300">
              Die folgenden Organisationen dürfen ohne vorherige schriftliche
              Genehmigung auf unsere Website verlinken:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>Regierungsbehörden;</li>
              <li>Suchmaschinen;</li>
              <li>Nachrichtenorganisationen;</li>
              <li>
                Online-Verzeichnisanbieter dürfen auf unsere Website verlinken,
                wie sie auch auf die Websites anderer gelisteter Unternehmen
                verlinken;
              </li>
              <li>
                Systemweit akkreditierte Unternehmen, ausgenommen gemeinnützige
                Organisationen, Wohltätigkeits-Shopping-Malls und
                Wohltätigkeits-Fundraising-Gruppen, die nicht auf unsere Website
                verlinken dürfen.
              </li>
            </ul>

            <p className="text-gray-300 mt-4">
              Diese Organisationen dürfen auf unsere Homepage, auf
              Veröffentlichungen oder andere Website-Informationen verlinken,
              solange der Link: (a) in keiner Weise irreführend ist; (b) nicht
              fälschlicherweise Sponsoring, Billigung oder Genehmigung der
              verlinkenden Partei und ihrer Produkte und/oder Dienstleistungen
              impliziert; und (c) in den Kontext der Website der verlinkenden
              Partei passt.
            </p>

            <p className="text-gray-300 mt-4">
              Wir können andere Linkanfragen von folgenden Arten von
              Organisationen prüfen und genehmigen:
            </p>

            <ul className="list-disc pl-5 text-gray-300">
              <li>
                allgemein bekannte Verbraucher- und/oder
                Geschäftsinformationsquellen;
              </li>
              <li>dot.com-Community-Seiten;</li>
              <li>
                Verbände oder andere Gruppen, die Wohltätigkeitsorganisationen
                vertreten;
              </li>
              <li>Online-Verzeichnisanbieter;</li>
              <li>Internetportale;</li>
              <li>Wirtschaftsprüfungs-, Rechts- und Beratungsfirmen;</li>
              <li>Bildungseinrichtungen und Berufsverbände.</li>
            </ul>

            <p className="text-gray-300 mt-4">
              Die Verwendung des Logos von Wks Company oder anderer Grafiken ist
              für die Verlinkung ohne Markenlizenzvereinbarung nicht gestattet.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              iFrames
            </h2>
            <p className="text-gray-300">
              Ohne vorherige Genehmigung und schriftliche Erlaubnis dürfen Sie
              keine Frames um unsere Webseiten erstellen, die die visuelle
              Darstellung oder das Erscheinungsbild unserer Website in
              irgendeiner Weise verändern.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Inhaltshaftung
            </h2>
            <p className="text-gray-300">
              Wir übernehmen keine Verantwortung für Inhalte, die auf Ihrer
              Website erscheinen. Sie stimmen zu, uns gegen alle Ansprüche zu
              schützen und zu verteidigen, die auf Ihrer Website entstehen. Auf
              keiner Website sollten Links erscheinen, die als verleumderisch,
              obszön oder kriminell interpretiert werden könnten oder die Rechte
              Dritter verletzen, anderweitig verstoßen oder deren Verletzung
              befürworten.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Ihre Privatsphäre
            </h2>
            <p className="text-gray-300">
              Bitte lesen Sie die Datenschutzerklärung
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Rechtevorbehalt
            </h2>
            <p className="text-gray-300">
              Wir behalten uns das Recht vor, Sie aufzufordern, alle Links oder
              bestimmte Links zu unserer Website zu entfernen. Sie stimmen zu,
              auf Anfrage sofort alle Links zu unserer Website zu entfernen. Wir
              behalten uns auch das Recht vor, diese Geschäftsbedingungen und
              ihre Verlinkungsrichtlinien jederzeit zu ändern. Indem Sie
              weiterhin auf unsere Website verlinken, stimmen Sie zu, an diese
              Verlinkungsbedingungen gebunden zu sein und diese zu befolgen.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Entfernung von Links von unserer Website
            </h2>
            <p className="text-gray-300">
              Wenn Sie einen Link auf unserer Website finden, der aus
              irgendeinem Grund anstößig ist, können Sie uns jederzeit
              kontaktieren und informieren. Wir werden Anfragen zur Entfernung
              von Links prüfen, sind aber nicht dazu verpflichtet oder müssen
              Ihnen direkt antworten.
            </p>
            <p className="text-gray-300 mt-4">
              Wir gewährleisten nicht, dass die Informationen auf dieser Website
              korrekt sind, wir garantieren nicht ihre Vollständigkeit oder
              Genauigkeit; noch versprechen wir sicherzustellen, dass die
              Website verfügbar bleibt oder dass das Material auf der Website
              auf dem neuesten Stand gehalten wird.
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Haftungsausschluss
            </h2>
            <p className="text-gray-300">
              Soweit nach geltendem Recht zulässig, schließen wir alle
              Zusicherungen, Gewährleistungen und Bedingungen in Bezug auf
              unsere Website und die Nutzung dieser Website aus. Nichts in
              diesem Haftungsausschluss wird:
            </p>
            <ul className="list-disc pl-5 text-gray-300">
              <li>
                unsere oder Ihre Haftung für Tod oder Körperverletzung
                einschränken oder ausschließen;
              </li>
              <li>
                unsere oder Ihre Haftung für Betrug oder betrügerische
                Falschdarstellung einschränken oder ausschließen;
              </li>
              <li>
                unsere oder Ihre Haftungen in einer Weise beschränken, die nach
                geltendem Recht nicht zulässig ist; oder
              </li>
              <li>
                irgendwelche unserer oder Ihrer Haftungen ausschließen, die nach
                geltendem Recht nicht ausgeschlossen werden können.
              </li>
            </ul>
            <p className="text-gray-300 mt-4">
              Die in diesem Abschnitt und an anderer Stelle in diesem
              Haftungsausschluss dargelegten Haftungsbeschränkungen und
              -verbote: (a) unterliegen dem vorstehenden Absatz; und (b) regeln
              alle Haftungen, die sich aus dem Haftungsausschluss ergeben,
              einschließlich Haftungen aus Vertrag, unerlaubter Handlung und
              Verletzung gesetzlicher Pflichten.
            </p>
            <p className="text-gray-300 mt-4">
              Solange die Website und die Informationen und Dienstleistungen auf
              der Website kostenlos zur Verfügung gestellt werden, haften wir
              nicht für Verluste oder Schäden jeglicher Art.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
