import Axios from "../../utils/axios";

export const fetchFooter = async () => {
    try {
        const response = await Axios.get(`/footer`);
        return response.data;
    } catch (error) {
        console.error('Error fetching jobs:', error);
        throw error;
    }
};