/* eslint-disable */

import React, { useState, useEffect } from "react";
import style from "../../styles/DienstleistungenSection.module.css";
import line from "../../assets/line.svg";
import { getServices } from "../../services/getServices";

const DienstleistungenSection = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await getServices();
        if (response && response) {
          setServices(response);
        }
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className={style.dienstleistungenSection}>
      <h1 className={style.h1}>{services?.title}</h1>

      <div className={style.maximizeSectionFirstContent}>
        {services?.services?.map((service, index) => (
          <div key={service.id}>
            <div className={style.dataCollection}>
              <div>
                <h5 className={style.h5}>
                  {index + 1}. {service.title}
                </h5>
                <p className={style.p}>{service.description}</p>
              </div>
            </div>
            <img src={line} alt="line" className={style.line} />
          </div>
        ))}
      </div>

      <div className={style.buttonRow}>
        <a className={style.button} href={services?.button_link}>
          {services?.button_text}
        </a>
      </div>
    </div>
  );
};

export default DienstleistungenSection;
