import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "../../styles/ClientSection.module.css";
import { getPartners } from "../../services/getPartners";

const ClientSection = () => {
  const [partners, setPartners] = useState([]);
  const [partnersTitle, setPartnersTitle] = useState("");
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await getPartners();

        if (response && response.partners) {
          setPartnersTitle(response?.title);
          const loadedPartners = response.partners.map((partner) => ({
            ...partner,
            image_url: `https://prodapi.wkscompany.ch/${partner.image_url}`,
          }));
          setPartners(loadedPartners);
        }
      } catch (error) {
        console.error("Failed to fetch partners:", error);
      }
    };

    fetchPartners();
  }, []);

  return (
    <div className={style.clientsSection}>
      <h3 className={style.h3}>{partnersTitle}</h3>
      <Slider {...settings}>
        {partners.map((partner, index) => (
          <div key={index} className={style.imageContainer}>
            <img src={partner.image_url} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientSection;
