import Axios from "../utils/axios";

const getPartners = async () => {
  try {
    const response = await Axios.get("/partners");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
export { getPartners };
