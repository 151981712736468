import axios from "../utils/axios";

export const getHeroSectionByPage = async (page) => {
  try {
    const response = await axios.get(`hero-sections/${page}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch hero section data", error);
    return null;
  }
};
