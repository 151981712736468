import React from "react";

const Impressum = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Impressum
        </h1>

        <div className="space-y-8">
          {/* Company Info Section */}
          <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
            <h2 className="text-2xl font-semibold mb-4 text-purple-400">
              Unternehmensdetails
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">Anbieter:</span>{" "}
                  WKS Company
                </p>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">Inhaber:</span>{" "}
                  Rexhepi Kirov Ajdin
                </p>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">
                    Anschrift:
                  </span>{" "}
                  Rr. Galip Badiviku 49, RKS - 42000 Vushtrri
                </p>
              </div>
              <div>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">Telefon:</span>{" "}
                  +383 492 44880
                </p>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">E-Mail:</span>{" "}
                  a.kirov@wkscompany.ch
                </p>
                <p className="text-gray-300">
                  <span className="font-medium text-purple-300">Web:</span>{" "}
                  wkscompany.ch
                </p>
              </div>
            </div>
          </div>

          {/* Legal Sections */}
          <div className="space-y-6">
            <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
              <h2 className="text-2xl font-semibold mb-4 text-purple-400">
                Haftungsausschluss
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Der Anbieter übernimmt keinerlei Gewähr für die Aktualität,
                Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
                Informationen. Haftungsansprüche gegen den Anbieter, welche sich
                auf Schäden materieller oder ideeller Art beziehen, die durch
                die Nutzung oder Nichtnutzung der dargebotenen Informationen
                bzw. durch die Nutzung fehlerhafter und unvollständiger
                Informationen verursacht wurden, sind grundsätzlich
                ausgeschlossen, sofern seitens des Anbieters kein nachweislich
                vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
              <h2 className="text-2xl font-semibold mb-4 text-purple-400">
                Verweise und Links
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Bei direkten oder indirekten Verweisen auf fremde Webseiten
                ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des
                Anbieters liegen, würde eine Haftungsverpflichtung
                ausschließlich in dem Fall in Kraft treten, in dem der Anbieter
                von den Inhalten Kenntnis hat und es ihm technisch möglich und
                zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu
                verhindern.
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
              <h2 className="text-2xl font-semibold mb-4 text-purple-400">
                Datenschutz
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Sofern innerhalb des Internetangebotes die Möglichkeit zur
                Eingabe persönlicher oder geschäftlicher Daten besteht, so
                erfolgt die Preisgabe dieser Daten seitens des Nutzers auf
                ausdrücklich freiwilliger Basis. Die Nutzung der im Rahmen des
                Impressums oder vergleichbarer Angaben veröffentlichten
                Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                angeforderten Informationen ist nicht gestattet.
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-6 backdrop-blur-sm shadow-xl">
              <h2 className="text-2xl font-semibold mb-4 text-purple-400">
                Google Analytics
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc. ("Google"). Google Analytics verwendet sog.
                „Cookies", Textdateien, die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglichen. Durch die Nutzung dieser Website erklären Sie sich
                mit der Bearbeitung der über Sie erhobenen Daten durch Google
                einverstanden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
