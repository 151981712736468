import React, { useEffect, useState } from "react";
import style from "./Karriere.module.css";
import { Link } from "react-router-dom";
import { getJobs } from "../../services/jobService";
const Karriere = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const getActiveJobs = async () => {
    const response = await getJobs();
    console.log("response", response);
    setJobs(response.jobs);
  };

  useEffect(() => {
    getActiveJobs();
  }, []);
  return (
    <div className={style.karriere}>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h2
          className={style.h22}
          style={{
            color: "#644dd9",
            fontFamily: `"Poppins"`,
            fontWeight: "700",
            fontSize: "38px",
            lineHeight: "70px",
          }}
        >
          Sind Sie bereit für eine neue Herausforderung?
        </h2>
        <p>Wir haben viele verschiedene Rollen anzubieten.</p>
      </div>

      <div className={style.karriereContent}>
        {jobs?.map((job, key) => (
          <div className={style.container} key={key}>
            <div className={style.card}>
              <div className={style.box}>
                <div className={style.content}>
                  <span className={style.heading}>{job.jobId}</span>
                  <span className={style.content}>{job.title} </span>
                  <p>{job.summary}</p>

                  {/* <a href="/apply-form">Jetzt bewerben</a> */}
                  <Link to={`/karriere/${job.id}`} className="apply-button">
                    Jetzt bewerben
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Karriere;
