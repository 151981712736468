import Axios from "../utils/axios";

const getAboutUsMainCardData = async () => {
  try {
    const response = await Axios.get("/about-us-main-card");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

const getAboutUsStatisticsData = async () => {
  try {
    const response = await Axios.get("/about-us-statistics");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
const getAboutUsServicesData = async () => {
  try {
    const response = await Axios.get("/about-us-services");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

const getAboutUsWhyChooseUsData = async () => {
  try {
    const response = await Axios.get("/about-us-why-choose-us");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

const getAboutUsFaqData = async () => {
  try {
    const response = await Axios.get("/about-us-faq");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export {
  getAboutUsMainCardData,
  getAboutUsStatisticsData,
  getAboutUsServicesData,
  getAboutUsWhyChooseUsData,
  getAboutUsFaqData,
};
