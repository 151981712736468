/* eslint-disable */
import React, { useEffect, useState } from "react";
import style from "./AboutUs.module.css";
import { Row, Col, Accordion, AccordionButton } from "react-bootstrap";
import { Row as AntRow, Col as AntCol, Card, Avatar, Skeleton } from "antd";
import { getTeamMembers } from "../../services/teamMembersService";
import {
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import {
  TrophyOutlined,
  RocketOutlined,
  UserOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import {
  getAboutUsFaqData,
  getAboutUsMainCardData,
  getAboutUsServicesData,
  getAboutUsStatisticsData,
  getAboutUsWhyChooseUsData,
} from "../../services/aboutUsService";

const UberUns = () => {
  const [selectedSection, setSelectedSection] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [mainCardData, setMainCardData] = useState([]);
  const [aboutUsStatisticsData, setAboutUsStatisticsData] = useState([]);
  const [aboutUsServicesData, setAboutUsServicesData] = useState([]);
  const [aboutUsWhyChooseUsData, setAboutUsWhyChooseUsData] = useState([]);
  const [aboutUsFaqData, setAboutUsFaqData] = useState({
    title: "",
    faqs: [],
  });

  const fetchTeamMembers = async () => {
    try {
      const response = await getTeamMembers();

      setTeamData(response.categories);
      const filteredCategories = response.categories.filter(
        (cat) => cat.members.length > 0
      );
      setTeamData(filteredCategories);
      setVisibleCategories(filteredCategories.slice(0, 2));
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const fetchMainCardData = async () => {
    try {
      const response = await getAboutUsMainCardData();
      if (response?.cards) {
        setMainCardData(response?.cards);
      }
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const fetchAboutUsStatisticsData = async () => {
    try {
      const response = await getAboutUsStatisticsData();

      if (response?.statistics) {
        setAboutUsStatisticsData(response?.statistics);
      }
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const fetchAboutUsServicesData = async () => {
    try {
      const response = await getAboutUsServicesData();
      if (response?.services) {
        setAboutUsServicesData(response?.services);
      }
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const fetchAboutUsWhyChooseUsData = async () => {
    try {
      const response = await getAboutUsWhyChooseUsData();
      if (response?.data) {
        setAboutUsWhyChooseUsData(response?.data);
      }
    } catch (error) {
      console.error("Failed to fetch team members:", error);
    }
  };

  const fetchAboutUsFaqData = async () => {
    try {
      const response = await getAboutUsFaqData();
      console.log("response", response);

      if (response) {
        setAboutUsFaqData(response);
        if (response?.data && response?.data.length > 0) {
          setSelectedSection(response?.data[0]?.main_title);
        }
      }
    } catch (error) {
      console.error("Failed to fetch FAQ data:", error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchMainCardData();
    fetchAboutUsStatisticsData();
    fetchAboutUsServicesData();
    fetchAboutUsWhyChooseUsData();
    fetchAboutUsFaqData();
  }, []);

  const handleShowMore = () => {
    if (!showMore) {
      setVisibleCategories(teamData);
    } else {
      setVisibleCategories(teamData.slice(0, 2));
    }
    setShowMore(!showMore);
  };

  return (
    <div>
      <div className={style.aboutUs}>
        <div className={style.firstSection}>
          <h1 style={{ fontSize: "2.5rem" }}>{mainCardData?.title}</h1>
          <p>{mainCardData?.description}</p>
        </div>
        <div className={style.iconContainer}>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#D4E6FE" }}
            >
              <RocketOutlined className={style.customIcon} />
            </span>
            {aboutUsStatisticsData[0]?.title ? (
              <span>{aboutUsStatisticsData[0].title}</span>
            ) : (
              <Skeleton.Input active size="small" />
            )}
          </div>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#FEECD4" }}
            >
              <UserOutlined className={style.customIcon} />
            </span>
            {aboutUsStatisticsData[1]?.title ? (
              <span>{aboutUsStatisticsData[1].title}</span>
            ) : (
              <Skeleton.Input active size="small" />
            )}
          </div>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#E5D4FE" }}
            >
              <SafetyOutlined className={style.customIcon} />
            </span>
            {aboutUsStatisticsData[2]?.title ? (
              <span>{aboutUsStatisticsData[2].title}</span>
            ) : (
              <Skeleton.Input active size="small" />
            )}
          </div>
        </div>
      </div>

      <div className={style.specialCard}>
        {aboutUsServicesData?.map((item, index) => (
          <div key={index} className={style.lastCard}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
          </div>
        ))}
      </div>

      <div className={style.whyWKSSection}>
        <div className={`${style.card} + card`}>
          {aboutUsWhyChooseUsData?.map((item, index) => (
            <div key={index} className={style.cardContent}>
              <h3 className={style.heading6}>{item.title}</h3>
              <p className={style.cardsParagraph}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={style.ourTeamSection}>
        {visibleCategories.map(
          (category, categoryIndex) =>
            category.members.length > 0 && (
              <div key={category.category_name}>
                <h3 className="mt-5">{category.category_name}</h3>
                <AntRow gutter={[16, 16]}>
                  {category.members.map((member, memberIndex) => (
                    <AntCol
                      xs={24}
                      sm={12}
                      lg={8}
                      key={member.id}
                      className={
                        categoryIndex === 0 &&
                        (memberIndex === 0 || memberIndex === 2)
                          ? style.specialMargin
                          : ""
                      }
                    >
                      <Card className={style.ourTeamCard}>
                        <div className={style.iconss}>
                          <Avatar
                            size={104}
                            src={member.image}
                            style={{
                              borderRadius: "30px 0 30px 0",
                              marginBottom: "1rem",
                              background: "white",
                            }}
                          />
                          <h5>{member.fullname}</h5>
                          <p style={{ fontFamily: "Poppins" }}>
                            {member.position}
                          </p>
                          <div className={style.socialMedia}>
                            {member.facebook_link && (
                              <a href={member.facebook_link}>
                                <FacebookOutlined className={style.icon} />
                              </a>
                            )}
                            {member.linkedin_link && (
                              <a href={member.linkedin_link}>
                                <LinkedinOutlined className={style.icon} />
                              </a>
                            )}
                            {member.twitter_link && (
                              <a href={member.twitter_link}>
                                <TwitterOutlined className={style.icon} />
                              </a>
                            )}
                          </div>
                        </div>
                      </Card>
                    </AntCol>
                  ))}
                </AntRow>
              </div>
            )
        )}
        {teamData.length > 2 && (
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleShowMore}
              style={{
                marginTop: "2rem",
                padding: "1rem 2rem",
                background: "linear-gradient(-45deg, #ab55a9 0%, #644dd9 100%)",
                borderRadius: "10px",
                border: "none",
                color: "white",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            >
              {showMore ? "Weniger" : "Mehr"}
            </button>
          </div>
        )}
      </div>
      <div className={style.helpCenter}>
        <div className={style.helpCenterContent}>
          <h3>{aboutUsFaqData?.title}</h3>
        </div>
        <Row>
          <Col xs={12} md={4}>
            <div className="d-flex w-100 flex-column gap-2">
              {aboutUsFaqData?.data &&
                aboutUsFaqData?.data.map((faq) => (
                  <div
                    key={faq.id}
                    id="qCard"
                    className={`${style.qCard} ${
                      selectedSection === faq.main_title
                        ? style.selectedCard
                        : ""
                    }`}
                    onClick={() => setSelectedSection(faq.main_title)}
                  >
                    <h6>{faq.main_title}</h6>
                    {selectedSection === faq.main_title ? (
                      <img
                        src="/assets/Whitearrow.svg"
                        alt="arrow"
                        className={style.img}
                      />
                    ) : (
                      <img
                        src="/assets/VectorArrow.svg"
                        alt="arrow"
                        className={style.img}
                      />
                    )}
                  </div>
                ))}
            </div>
          </Col>

          <Col md={8} xs={12} style={{ backgroundColor: "white" }}>
            <Accordion
              defaultActiveKey="0"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {aboutUsFaqData?.data &&
                aboutUsFaqData?.data
                  .filter((faq) => faq.main_title === selectedSection)
                  .map((faq, index) => (
                    <Accordion.Item
                      eventKey={index.toString()}
                      key={faq.id}
                      style={{
                        border: "1px solid #EEF2FF",
                        backgroundColor: "#EEF2FF",
                        padding: "0px 44px",
                      }}
                    >
                      <AccordionButton style={{ background: "none" }}>
                        <span className={style.accordionTitle}>
                          {faq.title}
                        </span>
                      </AccordionButton>
                      <Accordion.Body>{faq.description}</Accordion.Body>
                    </Accordion.Item>
                  ))}
            </Accordion>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UberUns;
