import Axios from "../utils/axios";

const getServicesForCards = async () => {
  try {
    const response = await Axios.get("/service-cards");

    return response?.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

const getKundenserviceData = async () => {
  try {
    const response = await Axios.get("/kundenservice");

    return response?.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
const getInboundData = async () => {
  try {
    const response = await Axios.get("/inbound");

    return response?.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
const getOutboundData = async () => {
  try {
    const response = await Axios.get("/outbound");
    return response?.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
export {
  getServicesForCards,
  getKundenserviceData,
  getInboundData,
  getOutboundData,
};
