/* eslint-disable */

import React, { useEffect, useState } from "react";
import style from "./ContactUs.module.css";
import Axios from "../../utils/axios";
import { toast, ToastContainer } from "react-toastify";
import { getContactInformation } from "../../services/contactService";
import { Skeleton } from "antd";

const Kontakt = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [contactInformation, setContactInformation] = useState([]);
  const [isContactInformationLoading, setIsContactInformationLoading] =
    useState(true);

  const clearInputs = () => {
    setName("");
    setEmail("");
    setMessage("");
    setErrors([]);
  };
  const validateForm = () => {
    let tempErrors = {};

    tempErrors.name = name
      ? name.length <= 255
        ? ""
        : "Name cannot exceed 255 characters."
      : "Name is required.";

    tempErrors.email = email
      ? /\S+@\S+\.\S+/.test(email) && email.length <= 255
        ? ""
        : "Email is not valid or exceeds 255 characters."
      : "Email is required.";

    tempErrors.message = message ? "" : "Message is required.";

    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every((x) => x === "");
  };

  // Function to send data when the form is submitted
  const fetchData = async (formData) => {
    try {
      const response = await Axios.post("/contact/store", formData);
      console.log("response", response);
      if (response.status == 201) {
        toast.success("Ihre Nachricht wurde erfolgreich gesendet");
        clearInputs();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = {
        name,
        email,
        message,
      };
      fetchData(formData);
    } else {
      console.log("Validation failed");
    }
  };

  const getContactInformationData = async () => {
    setIsContactInformationLoading(true);
    try {
      const data = await getContactInformation();
      setContactInformation(data);
    } catch (error) {
      console.error("Error fetching contact information:", error);
    } finally {
      setIsContactInformationLoading(false);
    }
  };

  useEffect(() => {
    getContactInformationData();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <section className={style.sectionBg} data-scroll-index="7">
        <div className={`${style.overlay} "pt-100 pb-100 "`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className={style.contactInfo}>
                  {/* <h2 className={style.contactTitle}>
                    {isContactInformationLoading ? (
                      <Skeleton.Input active size="large" />
                    ) : contactInformation?.sections?.[0]?.title ? (
                      contactInformation.sections[0].title
                    ) : (
                      <></>
                    )}
                  </h2>
                  <p>
                    {isContactInformationLoading ? (
                      <Skeleton active paragraph={{ rows: 2 }} />
                    ) : contactInformation?.sections?.[0]?.description ? (
                      contactInformation.sections[0].description
                    ) : (
                      <></>
                    )}
                  </p>
                  <ul className={style.contactInfo}>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-mobile-alt"></i>
                      </div>
                      <div className={style.infoRight}>
                        {isContactInformationLoading ? (
                          <Skeleton.Input active size="default" />
                        ) : (
                          contactInformation?.sections?.[0]?.phone_numbers?.map(
                            (number, index) => <h4 key={index}>{number}</h4>
                          )
                        )}
                      </div>
                    </li>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-at"></i>
                      </div>
                      <div className={style.infoRight}>
                        {isContactInformationLoading ? (
                          <Skeleton.Input active size="default" />
                        ) : (
                          contactInformation?.sections?.[0]?.emails?.map(
                            (email, index) => <h4 key={index}>{email}</h4>
                          )
                        )}
                      </div>
                    </li>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className={style.infoRight}>
                        {isContactInformationLoading ? (
                          <Skeleton.Input active size="default" />
                        ) : (
                          contactInformation?.sections?.[0]?.address?.map(
                            (address, index) => <h4 key={index}>{address}</h4>
                          )
                        )}
                      </div>
                    </li>
                  </ul> */}

                  {isContactInformationLoading ? (
                    <div
                      className={`flex gap-4 flex-col ${style.skeletonContainer}`}
                    >
                      <Skeleton.Input active size="large" />
                      <Skeleton active paragraph={{ rows: 2 }} />
                      <Skeleton.Input active size="small" />
                      <Skeleton.Input active size="small" />
                      <Skeleton.Input active size="small" />
                    </div>
                  ) : (
                    <>
                      <h2 className={style.contactTitle}>
                        {contactInformation?.sections?.[0]?.title}
                      </h2>
                      <p>{contactInformation?.sections?.[0]?.description}</p>
                      <ul className={style.contactInfo}>
                        <li>
                          <div className={style.infoLeft}>
                            <i className="fas fa-mobile-alt"></i>
                          </div>
                          <div className={style.infoRight}>
                            {contactInformation?.sections?.[0]?.phone_numbers?.map(
                              (number, index) => (
                                <h4 key={index}>{number}</h4>
                              )
                            )}
                          </div>
                        </li>
                        <li>
                          <div className={style.infoLeft}>
                            <i className="fas fa-at"></i>
                          </div>
                          <div className={style.infoRight}>
                            {contactInformation?.sections?.[0]?.emails?.map(
                              (email, index) => (
                                <h4 key={index}>{email}</h4>
                              )
                            )}
                          </div>
                        </li>
                        <li>
                          <div className={style.infoLeft}>
                            <i className="fas fa-map-marker-alt"></i>
                          </div>
                          <div className={style.infoRight}>
                            {contactInformation?.sections?.[0]?.address?.map(
                              (address, index) => (
                                <h4 key={index}>{address}</h4>
                              )
                            )}
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                style={{ background: "white" }}
              >
                <div className={style.contactForm}>
                  <form id="contact-form">
                    <input type="hidden" name="form-name" value="contactForm" />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="first-name"
                            placeholder="Bitte geben Sie Ihren Namen ein *"
                            required="required"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein *"
                            required="required"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            rows="4"
                            name="message"
                            className="form-control"
                            id="description"
                            placeholder="Bitte geben Sie Ihre Nachricht ein *"
                            required="required"
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          onClick={handleSubmit}
                          className={`${style.btnBig} ${style.btn} ${style.btnBg}`}
                        >
                          Absenden <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Kontakt;
