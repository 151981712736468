import Axios from "../utils/axios";

const getTeamMembers = async () => {
    try {
        const response = await Axios.get("/team-members");
        return response.data;
    } catch (error) {
        throw new Error(
            error.response ? error.response.data.message : error.message
        );
    }
};
export { getTeamMembers }

