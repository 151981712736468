/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import style from "../../styles/Header.module.css";
import bannerImg from "../../assets/home-banner-img.png";
import wave from "../../assets/waveSVG.svg";
import axios from "../../utils/axios";
import { getHeroSectionByPage } from "../../hooks/getHeroSectionByPage";
import { Skeleton } from "antd";

const Header = () => {
  const page = "homepage";
  const [heroSection, setHeroSection] = useState(null);

  const getHeroSectionData = async () => {
    try {
      const response = await getHeroSectionByPage(page);
      if (response && response.hero_section) {
        setHeroSection(response.hero_section);
      }
    } catch (error) {
      console.error("Error fetching hero section data", error);
    }
  };

  useEffect(() => {
    getHeroSectionData();
  }, []);

  return (
    <header className={style.headerStyle}>
      <Container className={style.container}>
        <div className={style.partOne} data-wow-duration="2s">
          {heroSection ? (
            <>
              <div className={style.content}>
                <h1>
                  <span>{heroSection.heading_text}</span>
                </h1>
              </div>
              <p className={style.paragraphhh}>
                <span>{heroSection.subheading_text}</span>
                <br />
              </p>

              <a className={style.buttonStyle} href={heroSection?.button_link}>
                {heroSection.button_text}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 5l7 7-7 7" />
                </svg>
              </a>
            </>
          ) : (
            <Skeleton active />
          )}
        </div>
        <img src={bannerImg} alt="Banner" className={style.imageStyle} />
        <img src={wave} alt="Wave" className={style.waveStyle} />
      </Container>
    </header>
  );
};

export default Header;
