import Axios from "../utils/axios";

const getContactInformation = async () => {
  try {
    const response = await Axios.get("/contact-informations");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export { getContactInformation };
